<template>
    <section class="page-header">
        <div class="page-title"><slot name="header"></slot></div>
        <div class="page-actions"><slot name="actions"></slot></div>
    </section>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.page-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.page-title {
    font-family: "Red Hat Display";
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
}
.page-actions {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}
</style>